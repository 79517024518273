.pricing {
  position: relative;
  display: flex;
  align-items: center;
  height: auto;
  background: transparent;
}

.section {
  width: 50%;
}

.costBlurb {
  position: relative;
  padding: 100px;
  vertical-align: text-bottom;
  min-height: 510px;
  background-color: #fff;
  margin: -50px 0;
  z-index: 1000;
  display: flex;
}

.costBlurb .content {
  margin: auto;
}

.costBlurb h3 {
  font-size: 30pt;
  color: #6d7946;
}

.farmPricing {
  background-color: #6d6d6d;
  color: #fff;
  padding: 50px;
  text-align: center;
  min-height: 150px;
}

.farmPricing:before, .conciergePricing:before {
  content: "";
  background-image: url(/static/media/pattern_stripe.d91b9f48.svg);
  background-size: 150px 150px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.farmPricing span {
  font-size: 8pt;
}

.conciergePricing {
  background-color: #6d7946;
  color: #fff;
  padding: 50px;
  text-align: center;
  min-height: 150px;
}

.farmPricing h1, .conciergePricing h1 {
  color: #fff;
  font-size: 50pt;
  position: relative;
  z-index: 100;
}

.farmPricing p, .farmPricing span, .conciergePricing p {
  position: relative;
  z-index: 100;
}

@media only screen and (max-width: 800px) {
  .section {
    transition: width 0.3s ease;
    width: 100%;
  }
  .pricing {
    display: block;
  }
  .costBlurb {
    margin: 0;
}
}