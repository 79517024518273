html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

p {
  margin-bottom: 10px;
  line-height: 1.5;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

/* GENERAL FONTS */

@font-face {
  font-family: 'OpenSans';
  src: url('./media/fonts/OpenSans.eot');
  src: url('./media/fonts/OpenSans.eot?#iefix') format('embedded-opentype'), url('./media/fonts/OpenSans.woff2') format('woff2'), url('./media/fonts/OpenSans.woff') format('woff'), url('./media/fonts/OpenSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansSB';
  src: url('./media/fonts/OpenSans-Semibold.eot');
  src: url('./media/fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'), url('./media/fonts/OpenSans-Semibold.woff2') format('woff2'), url('./media/fonts/OpenSans-Semibold.woff') format('woff'), url('./media/fonts/OpenSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansB';
  src: url('./media/fonts/OpenSans-Bold.eot');
  src: url('./media/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('./media/fonts/OpenSans-Bold.woff2') format('woff2'), url('./media/fonts/OpenSans-Bold.woff') format('woff'), url('./media/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaB';
  src: url('./media/fonts/ProximaNova-Bold.eot');
  src: url('./media/fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'), url('./media/fonts/ProximaNova-Bold.woff2') format('woff2'), url('./media/fonts/ProximaNova-Bold.woff') format('woff'), url('./media/fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaSB';
  src: url('./media/fonts/ProximaNova-Semibold.eot');
  src: url('./media/fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'), url('./media/fonts/ProximaNova-Semibold.woff2') format('woff2'), url('./media/fonts/ProximaNova-Semibold.woff') format('woff'), url('./media/fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SanchezRegular';
  src: url('./media/fonts/Sanchez-Regular.eot?#iefix') format('embedded-opentype'), url('./media/fonts/Sanchez-Regular.otf') format('opentype'), url('./media/fonts/Sanchez-Regular.woff') format('woff'), url('./media/fonts/Sanchez-Regular.ttf') format('truetype'), url('./media/fonts/Sanchez-Regular.svg#Sanchez-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  font-size: 16px;
  color: #333333;
  font-family: 'OpenSans', Arial;
  word-wrap: break-word;
  counter-reset: sanchezCounter;
}

a {
  text-decoration: none;
  color: #555c31;
}

p {
  font-size: 14px;
}

h1 {
  color: #555c31;
  font-size: 40px;
}

h2 {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}

h5 {
  color: #5c5c5c;
  font-size: 10px;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}

i {
  font-style: italic;
}

.dummy {
  -webkit-transition: all 450ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -moz-transition: all 450ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -ms-transition: all 450ms cubic-bezier(0.16, 0.16, 0.44, 1);
  transition: all 450ms cubic-bezier(0.16, 0.16, 0.44, 1);
}

.container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 24px;
  height: 2px;
  background-color: #333;
  margin: 0 0 6px;
  transition: 0.4s;
}

.bar3 {
  margin-bottom: 0;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-2px, 6px);
  transform: rotate(-45deg) translate(-2px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -9px);
  transform: rotate(45deg) translate(-6px, -9px);
}

.master {
  min-height: 600px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}

/* Welcome */

.welcome {
  background-image: url('./media/home.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
  height: 1500px;
  flex-direction: column;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 45px;
  z-index: 1000;
  -webkit-transition: background-color 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -moz-transition: background-color 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -ms-transition: background-color 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  transition: background-color 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
}

.lightOn .header {
  background-color: #fff;
}

.header_inner {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  display: inline-flex;
  position: relative;
}

.header_buttons {
  margin-left: 100px;
}

.header_inner .container {
  position: relative;
  float: left;
  top: 0;
  -webkit-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -moz-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -ms-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
}

.header_inner button {
  /* float: right; */
  margin: 13px 38px 0 0;
  -webkit-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -moz-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -ms-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
}

.header_inner button:hover {
  border-color: #555c31;
}

#miniLogo_Isolation_Mode {
  width: 58px;
  height: 24px;
  position: absolute;
  left: 30px;
  top: 30px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -moz-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -ms-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
}

.master.lightOn #miniLogo_Isolation_Mode {
  visibility: visible;
  opacity: 1;
  top: 10px;
}

.master.lightOn .container {
  top: -20px;
  visibility: hidden;
  opacity: 0;
}

.header .container {
  margin: 13px 0 0 30px;
}

.welcome_centered {
  max-width: 840px;
  height: 580px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  background-color: rgba(255, 255, 255, .8);
  padding: 30px;
  margin-top: 100px;
}

.welcome_centered h1 {
  margin: 48px 0 42px;
  font-family: 'ProximaNovaSB', Arial;
  font-size: 35px;
}

.welcome_centered p {
  max-width: 460px;
  margin: 0 auto 30px;
  line-height: 160%;
  color: #1d4620;
  font-family: 'OpenSansSB';
}

.welcome_centered .logo {
  margin-top: 10px;
}

.welcome_centered .logo img {
  display: block;
  margin: 0 auto;
}

.scrollDown {
  position: absolute;
  bottom: 20px;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 900;
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -moz-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -ms-transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
  transition: all 300ms cubic-bezier(0.16, 0.16, 0.44, 1);
}

.master.lightOn .scrollDown {
  visibility: hidden;
  opacity: 0;
  bottom: -30px;
}

.scrollDown span {
  color: #fff;
  font-size: 12px;
  margin-bottom: 8px;
  display: block;
}

.scrollDown button {
  background: transparent;
  border: 0;
  display: block;
  margin: 0 auto 25px;
  outline: 0;
}

.scrollDown button:hover {
  opacity: .8;
}

/* Featured */

.featured {
  background-color: #cd7925;
  position: relative;
  overflow: hidden;
  padding: 100px 0 120px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.simple {
  position: relative;
}

.simple:before, .featured:before {
  content: "";
  background-image: url('./media/pattern_stripe.svg');
  background-size: 150px 150px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: 9;
}

.featured svg {
  width: 650px;
  position: absolute;
  top: -240px;
  right: 0;
}

.featured svg path {
  fill: #c58934;
}

.featured h1 {
  color: #fff;
  position: relative;
  z-index: 9;
  line-height: 130%;
  max-width: 800px;
  font-family: 'ProximaNovaB', Arial;
  font-size: 36px;
  letter-spacing: .6px;
}

/* Founded */

.founded {
  align-items: center;
  background: #f3f3f3;
}

.founded_inner {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
}

.founded_left {
  padding: 70px 150px;
  max-width: 820px;
  position: relative;
  z-index: 900;
  background: #f3f3f3;
}

.founded_left span {
  text-transform: uppercase;
  color: #555c31;
  font-size: 14px;
  font-family: 'ProximaNovaB', Arial;
  margin-bottom: 15px;
  display: inline-block;
  vertical-align: top;
}

.founded_left h1 {
  font-size: 40px;
  font-family: 'ProximaNovaSB', Arial;
}

.founded_left p {
  color: #3a3a3a;
  line-height: 140%;
  margin: 30px 0;
  line-height: 160%;
}

.founded_right {
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 90;
}

/* Doing */

.doing {
  background: #737b4c;
  padding: 120px 0 145px;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  position: relative;
}

.doing_inner {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
}

.doing_left {
  float: left;
  width: 580px;
  min-height: 500px;
}

.doing_left div {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  -webkit-transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -moz-transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -ms-transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
  transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
}

.doing_left div.layer_presentation_01 {
  z-index: 3;
  margin-top: -30px;
  transition-delay: 1s;
}

.doing_left div.layer_presentation_02 {
  margin-top: 80px;
  z-index: 2;
  transition-delay: .5s;
}

.doing_left div.layer_presentation_03 {
  margin-top: 190px;
  z-index: 1;
}

.animate_01 {
  -webkit-animation: KEYFRAME-NAME 1s;
}

.animate_02 {
  -webkit-animation: KEYFRAME-NAME 3s;
}

.animate_03 {
  -webkit-animation: KEYFRAME-NAME 5s;
}

@-webkit-keyframes KEYFRAME-NAME {
  0% {
    opacity: 0;
    left: -100px;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.animate .doing_left div.layer_presentation_01 {
  margin-top: 0;
  visibility: visible;
  opacity: 1;
}

.animate .doing_left div.layer_presentation_02 {
  margin-top: 120px;
  visibility: visible;
  opacity: 1;
}

.animate .doing_left div.layer_presentation_03 {
  margin-top: 230px;
  visibility: visible;
  opacity: 1;
}

.doing_right {
  float: right;
  width: 650px;
  padding-top: 50px;
  position: relative;
  z-index: 90;
}

.doing_right h1 {
  color: #fff;
  font-size: 30px;
  font-family: 'ProximaNovaSB', Arial;
}

.doing_right p {
  color: #fff;
  line-height: 160%;
}

.doing_right ol li {
  margin: 45px 0 65px;
  padding-left: 65px;
  position: relative;
}

.doing_right ol li:before {
  counter-increment: sanchezCounter;
  content: "" counter(sanchezCounter) "";
  font-size: 79px;
  font-family: 'SanchezRegular', Arial;
  color: #838c60;
  float: left;
  line-height: 0.9;
  margin-right: 15px;
  position: absolute;
  top: 10px;
  left: 0;
}

.doing_right ol li h5 {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  line-height: 160%;
  font-family: 'OpenSansSB', Arial;
}

.svgShape_outside {
  height: 100%;
  transform: rotate(180deg);
  position: absolute;
  top: 50px;
  left: 0;
}

.svgShape_inside {
  height: 90%;
  transform: rotate(180deg);
  position: absolute;
  top: 90px;
  left: -340px;
}

/* Simple */

.simple {
  background-color: #cd7925;
  background-image: url('./media/featreud_shape.svg');
  background-repeat: repeat;
  background-size: auto 100%;
}

.simple_inner {
  display: block;
  width: 100%;
  padding: 0 0 100px;
  max-width: 1300px;
  margin: 0 auto;
}

.simple_content {
  background: #fff;
  padding: 45px 75px 0;
  width: calc(100% - 80px);
  margin: -85px 0 0 80px;
  z-index: 9;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 85px;
}

.simple_content:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  right: -50%;
}

.simple_content_inner {
  position: relative;
  z-index: 90;
}

.simple_content h1 {
  font-size: 30px;
  font-family: 'ProximaNovaSB', Arial;
}

.simple_content p {
  line-height: 130%;
  font-size: 13px;
}

.simple_list {
  float: left;
  width: 100%;
  margin: 0;
  position: relative;
}

.simple_list li {
  margin: 50px 0;
  position: relative;
  padding-left: 60px;
  width: 38%;
}

.simple_list li:last-of-type {
  float: right;
  max-width: 801px;
  position: absolute;
  width: 100%;
  top: 0;
  padding: 0;
  right: -100px;
}

.simple_list li svg {
  width: 46px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 0;
}

.simple_list h5 {
  color: #3a3a3a;
  font-family: 'OpenSansSB', Arial;
  font-size: 18px;
  margin-bottom: 5px;
}

.simple_list p {
  color: #3a3a3a;
}

.simple_featured_display {
  float: right;
  max-width: 803px;
  height: 471px;
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.simple_featured_display img {
  position: absolute;
  top: 0;
  opacity: 1;
  -webkit-transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -moz-transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -ms-transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
  transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
}

.displayDesktop {
  right: -100px;
  opacity: 0;
}

.animateDesktop {
  -webkit-animation: ADMIN 2s;
}

.animatePhone {
  -webkit-animation: ADMIN 3s;
}

@-webkit-keyframes ADMIN {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
    -moz-transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
    -ms-transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
    transition: all 700ms cubic-bezier(0.16, 0.16, 0.44, 1);
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

/* Discover */

.discover {
  padding: 70px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  background-image: url('./media/footer_bg.jpg');
  background-size: cover;
  background-position: 0px 380px;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.discover_inner {}

.discover h1 {
  margin-bottom: 20px;
  font-family: 'ProximaNovaSB', Arial;
  font-size: 35px;
}

.discover p {
  color: #1d4620;
  line-height: 140%;
  max-width: 380px;
  margin: 0 auto;
  display: inline-block;
  vertical-align: top;
  font-family: 'OpenSansSB', Arial;
  line-height: 160%;
}

.discover .connection {}

/* Privacy  */

.textContainer {
  width: 90%;
  margin: 50px auto;
  overflow: auto;
  background-color: #fff;
  padding: 30px;
}

.textContainer h1 {
  margin-bottom: 20px;
}

.textContainer u, b {
  color: #000;
  font-size: 1em;
  font-weight: bold;
}

.textContainer li {
  margin: 10px 0;
  line-height: 1.3;
}

.textContainer p {
  margin: 10px 0;
  line-height: 1.3;
}

.termsOfUse ul li {
  color: #000;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.3;
}
.videoLogoContainer{
  background-color: transparent;
  margin: auto;
}
.videoLogoContainer .btn_video{
  font-size: 4.5em;
}
.whiteCircle {
  color: #fff;
  /* opacity: 0; */
}
/* Copyright */

.copyright {
  min-height: 40px;
  background: #fff;
}

.copyright p {
  height: 40px;
  line-height: 1.5;
  color: #a0a0a0;
  text-align: center;
}

/* Misc */

.separator {
  height: 5px;
  width: 100px;
  display: block;
  margin: 20px 0;
}

.separator._center {
  margin: 30px auto 35px;
}

.separator svg {
  height: 5px;
  display: inline-block;
  vertical-align: top;
}

.separator._white svg path {
  stroke: #fff;
}

.separator._forest svg path {
  stroke: #555c31;
}

.btn_xs {
  width: 71px;
  height: 19px;
  background-color: #cd7925;
  border: 1px solid #cd7925;
  outline: 0;
  border-radius: 0;
  color: #fff;
  text-align: center;
  line-height: 1;
  font-family: 'ProximaNovaB', Arial;
  text-transform: uppercase;
  font-size: 10px;
}

.btn_md {
  min-width: 150px;
  min-height: 40px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  border: none;
  font-family: 'ProximaNovaB', Arial;
  border-radius: 0;
  font-size: 14px;
  outline: 0;
  margin: 0 8px;
}

.outlinedBtn {
  border: 1px solid #555c31;
}

._colorForest {
  background-color: #cd7925;
  border: 1px solid #cd7925;
  color: #fff;
  -webkit-transition: all 150ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -moz-transition: all 150ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -ms-transition: all 150ms cubic-bezier(0.16, 0.16, 0.44, 1);
  transition: all 150ms cubic-bezier(0.16, 0.16, 0.44, 1);
}

._colorForest:hover {
  background-color: #758041;
}

._colorTrans {
  background-color: transparent;
  color: #555c31;
  -webkit-transition: all 150ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -moz-transition: all 150ms cubic-bezier(0.16, 0.16, 0.44, 1);
  -ms-transition: all 150ms cubic-bezier(0.16, 0.16, 0.44, 1);
  transition: all 150ms cubic-bezier(0.16, 0.16, 0.44, 1);
}

._colorWhite {
  background-color: #fff;
  color: #fff;
  border: 1px solid #fff;
}

._colorTrans:hover {
  background-color: #fff;
}

._bannerButton {
  background-color: rgba(255, 255, 255, .1);
  min-height: auto;
}

.btn_video {
  background: transparent;
  border: 0;
  color: #555c31;
  font-weight: bold;
  font-size: 1em;
  padding: 10px;
}

.lightbox {
  position: relative;
  z-index: 1001
}
.ril-toolbar{
  display: none;
}
.videoContainer{
  margin: 10px;
}
.ril-outer:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
.ReactModal__Content--after-open{
  background-color: rgba(0, 0, 0, .5) !important;
}
.ril-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.closeLightBox{
  color: #fff;
  font-size: 36px;
  z-index: 1002;
  position: fixed;
  border: 0;
  background-color: transparent;
  width: 50px;
  margin: 10px;
  right: 15px;
  top: 0px;
}
iframe {
  height: 90vh;
  width: 90vw;
  top: 10%;
  position: relative;
  left: 5%;
}

.mini_logo_st0 {
  fill: #d27928
}

.mini_logo_st1 {
  fill: #5f6638
}

.sl_svg1_st0 {
  fill: none;
  stroke: #000;
  stroke-width: .7;
  stroke-linecap: round;
  stroke-miterlimit: 10
}

.sl_svg2_st0 {
  fill: none;
  stroke: #000;
  stroke-width: .7;
  stroke-linecap: round;
  stroke-miterlimit: 10
}

.sl_svg3_st0 {
  fill: none;
  stroke: #000;
  stroke-width: .7;
  stroke-linecap: round;
  stroke-miterlimit: 10
}

.ReactModal__Body--open {
  overflow: hidden;
}

.Notification .ReactModal__Content--after-open {
  z-index: 1000;
}

.Notification {
  color: #555c31;
  max-width: 40%;
}

.Notification h2 {
  margin-top: 20px;
  color: #555c31;
  line-height: 1.5;
  text-align: center;
}

.Notification .logo {
  text-align: center;
}

.Notification .close-btn {
  text-align: right;
}

.Notification .close-btn button {
  background: none;
  border: none;
  color: #555c31;
  font-size: 20px;
  font-weight: 700;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75)!important;
}

.Notification {
  background: white!important;
}

.close-btn-wrapper {
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 1480px) {
  .simple_list li:last-of-type {
    max-width: 701px;
    right: 0;
  }
  .simple_featured_display {
    max-width: 703px;
  }
}

@media (max-width: 1300px) {
  .simple_list {
    margin: 25px 0;
  }
  .simple_inner {
    padding-bottom: 200px;
  }
  .simple_list li {
    width: 50%;
    float: left;
    margin: 25px 0;
    display: block;
  }
  .simple_list li:last-of-type {
    max-width: 730px;
    width: 50%;
    top: 0;
    right: 0;
    padding: 0;
    position: relative;
  }
  .simple_featured_display {
    position: absolute;
  }
  .simple_featured_display img {
    height: auto;
  }
  .welcome_centered, .featured h1, .discover_inner, .doing_inner {
    width: 100%;
  }
  .doing_left div, .doing_left, .doing_right {
    width: 50%;
  }
  .founded_left {
    padding: 5%;
    width: 70%;
  }
}

@media (max-width: 978px) {
  .simple_inner {
    text-align: center;
  }
  .simple_content_inner {
    text-align: left;
  }
  .simple_content {
    width: 90%;
    padding: 45px 35px 0;
    margin: -85px auto;
  }
  .simple_list li {
    width: 100%;
  }
  .simple_list li:last-of-type {
    width: 100%;
  }
  .simple_featured_display {
    margin-top: 0;
  }
  .simple_content:after {
    display: none;
  }
  .simple_inner {
    padding-bottom: 480px;
  }
  .doing_right {
    padding-top: 0;
  }
  .founded_left span {
    margin: 10px 0;
  }
  .founded_left p {
    margin: 10px 0;
  }
  .founded_right {
    width: 80%;
  }
  .founded_right img {
    float: right;
    max-height: 540px;
  }
  .Notification {
    max-width: 70%;
  }
}

@media (max-width: 768px) {
  .simple_inner {
    padding-bottom: 380px;
  }
  .doing_left div, .doing_left {
    width: 100%;
  }
  .doing_right {
    width: 100%;
    padding-top: 80px;
  }
  .founded_left {
    width: 90%;
    margin: 0 auto;
  }
  .founded_right {
    display: none;
  }
  .founded_right img {
    max-height: 500px;
  }
  .founded_inner {
    background-image: url('./media/featured.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0;
  }
  .doing {
    padding-top: 60px;
  }
  .welcome_centered p {
    margin: 0 auto 20px;
  }
}

@media (max-width: 845px) {
  .welcome_centered p {
    margin: 0 auto 20px;
  }
}

@media (max-width: 660px) {
  .header {
    height: 80px;
  }

  .Notification {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .welcome_centered h1 {
    margin: 20px 0 42px;
  }
  .welcome_centered p {
    margin: 0 auto 10px;
  }
  .scrollDown {
    bottom: -20px;
  }
  .simple_inner {
    padding-bottom: 280px;
  }
  .header_inner button {
    min-width: 100px;
  }
  .header_inner button {
    margin: 13px 5px 0 0;
  }
  .Notification {
    max-width: 90%;
  }
}

.Notification {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}